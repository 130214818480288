@use "sass:meta" as ---fs4nvhie6me;.doska-popular {
  .picture {
    display: block;
    margin-bottom: .5rem;
  }

  .title {
    display: block;
    font-size: rem-calc(13);
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .company, .user {
    display: block;
    font-size: rem-calc(12);
    font-weight: $global-weight-bolder;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

;@include ---fs4nvhie6me.load-css("sass-embedded-legacy-load-done:3754");