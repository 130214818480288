@use "sass:meta" as ---fs4nvhie6me;@use 'sass:math';

.doska-sidebar {
  &.cell {
    @include breakpoint(small) {
      order: 1;
    }

    @include breakpoint(medium down) {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    @include breakpoint(large) {
      order: 2;
      width: 240px;
    }
  }

  &__filters {
    @include breakpoint(large) {
      border-radius: $global-radius;
      background-color: $alice-blue;
      margin-bottom: 2rem;
      padding: 15px;
    }

    #collapse {
      .wrapper {
        @include breakpoint(medium down) {
          display: none;
        }
      }

      &_button {
        @include breakpoint(medium down) {
          background-color: map-get($foundation-palette, san-marino);
          border-radius: $global-radius;
          padding: 10px 0 10px 10px;
          color: $white;
          font-weight: bold;
          position: relative;

          &.expanded {
            font-weight: $global-weight-bolder;

            &::after {
              transform: rotate(180deg);
              transform-origin: 50% 50%;
            }
          }

          &::after {
            @include css-triangle(6px, $white, down);

            display: none;
            position: absolute;
            top: 50%;
            margin-top: -3px;
            right: 1rem;
          }

          &.visible {
            &::after {
              display: block;
            }
          }
        }

        @include breakpoint(large) {
          display: none;
        }
      }

      &.collapse-wrapper {
        @include breakpoint(medium down) {
          background-color: $alice-blue;
          border-radius: 0 0 15px 15px;

          .wrapper {
            padding: 15px;
          }
        }
      }
    }

    fieldset {
      display: flex;
      flex-direction: column;
      gap: 8px;

      legend {
        font-weight: $global-weight-bolder;
        color: $black;
      }

      input {
        flex-shrink: 0;
      }

      label {
        align-items: center;
        color: $anchor-color;
        display: flex;
        line-height: 1.3;
      }
    }

    .search {
      display: flex;

      input {
        width: 100%;
        border: 1px solid $medium-gray;
        border-radius: $global-radius 0 0 $global-radius;
        padding: 7px;
        border-right: none;
      }

      button {
        width: 34px;
        flex-shrink: 0;
        background-color: $medium-gray;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0 $global-radius $global-radius 0;

        &::before {
          position: absolute;
          background-size: 18px;
          background-image: url('../../../styles/common/svg/search-button-inline.svg');
          content: '';
          height: 18px;
          width: 18px;
          vertical-align: text-bottom;
        }

        &:hover {
          background-color: darken($medium-gray, 20%);
          cursor: pointer;
        }

        span {
          position: absolute;
          width: 1px;
          height: 1px;
          clip: rect(1px, 1px, 1px, 1px);
        }
      }
    }

    .filter-item {
      margin: $global-margin 0;

      fieldset {
        overflow-y: scroll;

        &::-webkit-scrollbar-thumb {
          background-color: map-get($foundation-palette, san-marino);
          border-radius: 100px;
        }

        &::-webkit-scrollbar {
          width: 10px;
        }
      }

      .hide {
        display: none;

        &-onScroll {
          height: 210px;
        }
      }

      .show-more {
        color: #f00;
        margin-top: .8rem;
        cursor: pointer;
        font-size: rem-calc(14);
      }
    }

    .controls {
      display: flex;
      margin-top: rem-calc(30);
      gap: 10px;

      .button {
        margin-bottom: 0;

        &.submit {
          flex-grow: 1;
        }

        &.reset {
          flex-shrink: 0;
        }
      }
    }
  }

  #bs22,
  #bs51,
  #bs52,
  #bs53 {
    @include grid-column-margin;
  }
}

;@include ---fs4nvhie6me.load-css("sass-embedded-legacy-load-done:3768");