@use "sass:meta" as ---eyygoxs6v1;@use 'sass:math';

.commerce {
  position: relative;
  display: block;
  margin-bottom: 1rem;
  border: 1px solid #2a99d6;
  box-sizing: border-box;
  overflow: hidden;

  @include breakpoint(medium) {
    height: 224px;
  }

  &__thumb {
    @include obj-thumb(100%, 134px);

    img {
      display: block;
      margin: auto;
    }
  }

  &:hover .commerce__caption {
    bottom: 0;
  }

  &__caption {
    background-color: #2a99d6;
    color: $white;
    padding: math.div($global-padding, 2) $global-padding;
    width: 100%;

    @include breakpoint(medium) {
      transition: all .3s;
      position: absolute;
      bottom: -135px;
      height: 224px;
    }

    &_active {
      bottom: 0;
    }
  }

  &__tag {
    text-transform: lowercase;

    @include breakpoint(medium) {
      font-size: rem-calc(12);
    }
  }

  &__title {
    text-transform: uppercase;
    font-weight: $global-weight-bold;
    overflow: hidden;
    word-break: break-all;
    margin-bottom: math.div($global-margin, 4);

    @include breakpoint(medium) {
      font-size: rem-calc(13);
      line-height: rem-calc(19);
      height: rem-calc(19 * 2);
    }
  }

  &__text {
    font-size: rem-calc(14);
    max-height: 5rem;
    overflow: hidden;

    @include breakpoint(medium) {
      font-size: rem-calc(13);
      max-height: none;
    }
  }

  &-b {
    @include show-for(medium);

    &__link {
      display: block;
      background-color: #2a99d6;
      height: 224px;
      box-sizing: border-box;
      margin-bottom: 10px;
      padding: $global-padding;
      text-align: center;
      color: $white;
      transition: all .3s;
      position: relative;

      &:hover,
      &:visited,
      &:focus {
        color: $white;
      }
    }

    &_4-4 {
      width: 100%;

      .commerce-b__link {
        height: 160px;
      }

      .commerce-b__text {
        margin-top: 20px;
        margin-right: 46px;
      }

      .commerce-b__title {
        font-size: rem-calc(32);
        line-height: 24px;
        margin-bottom: 24px;
      }

      .commerce-b__button {
        font-size: rem-calc(22);
        padding: 8px 49px;
      }

      .commerce-b__img {
        width: 111px;
        height: 116px;
        margin-top: 5px;
      }
    }

    &_3-4 {
      width: 75%;

      .commerce-b__text {
        margin-top: 51px;
        margin-right: 46px;
      }

      .commerce-b__title {
        font-size: rem-calc(32);
        line-height: 24px;
        margin-bottom: 24px;
      }

      .commerce-b__button {
        font-size: rem-calc(22);
        padding: 8px 49px;
      }

      .commerce-b__img {
        width: 111px;
        height: 116px;
        margin-top: 37px;
      }
    }

    &_2-4 {
      width: 50%;

      .commerce-b__text {
        margin-top: 46px;
        margin-right: 20px;
      }

      .commerce-b__title {
        font-size: rem-calc(22);
        line-height: 24px;
        margin-bottom: 20px;
      }

      .commerce-b__button {
        font-size: rem-calc(20);
        padding: 19px 40px;
      }

      .commerce-b__img {
        width: 116px;
        height: 121px;
        margin-top: 31px;
      }
    }

    &_1-4 {
      width: 25%;

      .commerce-b__text {
        margin-top: 91px;
      }

      .commerce-b__title {
        margin-bottom: 10px;
      }

      .commerce-b__button {
        padding: 10px 40px;
        font-size: rem-calc(15);
      }

      .commerce-b__img {
        width: 70px;
        height: 73px;
        margin-top: 10px;
        position: absolute;
        left: 72px;
        top: 8px;
      }
    }

    &__text {
      text-transform: uppercase;
      display: inline-block;
      vertical-align: top;
    }

    &__title {
      font-size: rem-calc(20);
      line-height: 24px;
    }

    &__button {
      background-color: $tangerine-yellow;
      display: inline-block;

      &:hover {
        background-color: scale-color($tangerine-yellow, $lightness: -15%);
      }
    }

    &__img {
      display: inline-block;
      background-repeat: no-repeat;
      background-image: url('./svg/doska-commerce-bg.svg');

      > img {
        max-width: 100%;
      }
    }

    @include breakpoint(small only) {
      width: 100%;

      & &__link {
        height: auto;
      }

      & &__text {
        margin: 200px 20px 20px;
      }

      & &__title {
        font-size: rem-calc(28);
        line-height: 1.2;
        margin-bottom: 22px;
      }

      & &__button {
        font-size: rem-calc(22);
        padding: 8px 49px;
      }

      & &__img {
        width: 150px;
        margin-top: 0;
        position: absolute;
        left: calc(50% - 75px);
        top: 45px;
      }
    }
  }
}

.row.commerce-set {
  margin-bottom: 1rem;

  @include breakpoint(small only) {
    flex-direction: column;
  }
}

;@include ---eyygoxs6v1.load-css("sass-embedded-legacy-load-done:3704");